import { template as template_bbca142f42704b9fa63a7b19a553fe14 } from "@ember/template-compiler";
const FKControlMenuContainer = template_bbca142f42704b9fa63a7b19a553fe14(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
